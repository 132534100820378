<template>
  <a-row type="flex" justify="center" align="middle">
    <a-col :xs="9" :sm="9">
      <sd-heading v-if="!skipTitle" class="registration-heading" as="h3">
        {{ $t('views.registration.title') }}
      </sd-heading>
    </a-col>
    <a-col :xs="6" :sm="6" class="company-logo-col">
      <router-link to="/"><img :src="require('/public/gce_logo.png')" alt=""/></router-link>
    </a-col>
    <a-col :xs="9" :sm="9" />
  </a-row>
</template>

<script>
import {defineComponent} from 'vue';
import VueTypes from 'vue-types';

export default defineComponent({
  name: 'RegistrationHeading',
  props: {
    skipTitle: VueTypes.bool.def(false)
  }
});
</script>

<style lang="scss" scoped>
.flex-col {
  display: flex;
}

.company-logo-col {
  text-align: center !important;
}

.company-logo-col img {
  width: 70px !important;
}
</style>
