<template>
  <Main>
    <div class="auth-contents registration-form">
      <a-row :gutter="15">
        <a-col :xs="24" class="mt-20">
          <registration-heading />
        </a-col>
        <a-col class="mt-10" :xs="24">
          <sdCards headless>
            <basic-steps step="withIcon" size="default" :data="steps" :current="current" />
          </sdCards>
        </a-col>
        <a-col :xs="24">
          <company-details
            v-show="current === 0"
            :data="state.companyDetails"
            :loading="buttonLoading"
            @submit:companyDetails="handleCompanyDetails"
          />
          <product-items
            v-show="current === 1"
            :data="state.productItems"
            @previousStep="prevStep('productItems', $event)"
            :loading="buttonLoading"
            @submitForm="handleProductItems"
          />
          <terms-and-conditions
            v-show="current === 2"
            @previousStep="prevStep(undefined, $event)"
            :loading="buttonLoading"
            @finishRegistration="submitForm"
          />
        </a-col>
      </a-row>
    </div>
  </Main>
</template>

<script>
import {
  reactive, ref
} from 'vue';
import {useStore} from 'vuex';
import _ from 'lodash';
import BasicSteps from '@/components/steps/Basic';
import CompanyDetails from '@/components/registration/CompanyDetails';
import ProductItems from '@/components/registration/ProductItemsForm';
import {Main} from '@/components/shared/styledComponents/Main';
import {useI18n} from 'vue-i18n';
import TermsAndConditions from '@/components/registration/TermsAndConditions';
import RegistrationHeading from '@/components/registration/Heading';

export default {
  name: 'ClientRegistration',
  components: {
    Main,
    BasicSteps,
    CompanyDetails,
    ProductItems,
    TermsAndConditions,
    RegistrationHeading
  },
  setup() {
    const {dispatch, commit} = useStore();
    commit('setUserType', 'ClientUser');

    dispatch('toggleLoading', true);
    Promise.all([
      dispatch('getCountries'),
      dispatch('getList', 'PRODUCT_CATEGORIES')
    ]).then(() => dispatch('toggleLoading', false));

    const loading = ref(false);
    const buttonLoading = ref(false);
    const status = ref('process');
    const current = ref(0);

    const {t} = useI18n();
    const steps = [
      {
        id: 1,
        title: t('views.registration.companyDetails'),
        icon: 'home'
      },
      {
        id: 2,
        title: t('views.registration.products'),
        icon: 'shopping-cart'
      },
      {
        id: 3,
        title: t('views.registration.termsAndConditions'),
        icon: 'clipboard'
      }
    ];

    const state = reactive({companyDetails: {}, productItems: {}});
    const saveState = (key, data) => Object.assign(state[key], {...state[key], ...data});

    const handleCompanyDetails = (data) => {
      buttonLoading.value = true;
      const formData = new FormData();
      formatCompanyDetails(formData);
      dispatch('validateCompany', formData).then((status) => {
        buttonLoading.value = false;
        if (status === 204) {
          saveState('companyDetails', data);
          current.value += 1;
        }
      });
    };

    const prevStep = (key, data) => {
      if (key) saveState('productItems', data);
      current.value -= 1;
    };

    const handleProductItems = (data) => {
      saveState('productItems', data);
      current.value += 1;
    };

    const submitForm = () => {
      const formData = new FormData();
      formatCompanyDetails(formData);

      _.forEach(state.productItems, (item, index) => {
        _.forEach(item, (value, key) => {
          if (key !== 'dataSheet') {
            formData.append(`${_.snakeCase('clientAttributes')}[${_.snakeCase('productItemsAttributes')}][${index}][${_.snakeCase(key)}]`, value || '');
          } else if (!_.isEmpty(value) && value[0] instanceof File ) {
            formData.append(`${_.snakeCase('clientAttributes')}[${_.snakeCase('productItemsAttributes')}][${index}][${_.snakeCase(key)}]`, value[0]);
          }
        });
      });

      formData.append(`${_.snakeCase('clientAttributes')}[${_.snakeCase('termsAndConditions')}]`, true);

      buttonLoading.value = true;
      Promise.all([
        dispatch('registerCompany', {data: formData}),
        dispatch('getSystemConfigurationByKey', _.snakeCase('allowedShipmentRequestsForNonVerifiedClient'))
      ]).then((status) => {
        buttonLoading.value = false;
        if (status !== 200) current.value = current.value - 1;
      });
    };

    const formatCompanyDetails = (formData) => {
      _.forEach(state.companyDetails, (value, key) => {
        if (_.includes(['email', 'password', 'passwordConfirmation', 'firstName', 'lastName'], _.camelCase(key))) {
          formData.append(_.snakeCase(key), value);
        } else if (key === 'tradeLicense' || key === 'businessRegistrationCertificate') {
          formData.append(`${_.snakeCase('clientAttributes')}[${_.snakeCase(key)}]`, value[0]);
        } else {
          formData.append(`${_.snakeCase('clientAttributes')}[${_.snakeCase(key)}]`, value);
        }
      });
    };

    return {
      loading,
      buttonLoading,
      status,
      current,
      steps,
      state,
      saveState,
      submitForm,
      prevStep,
      handleCompanyDetails,
      handleProductItems
    };
  }
};
</script>
